import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MStatusPlates, {MSeverityPlates} from "../components/MStatusPlates";
import { formatDateTimeToShow, formatStudyType } from "../utils/utils";
import { MButton } from "../components/MButton";
import { baseUrls } from "../routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MTextField from "../components/MTextField";
import {StudyStatusesEnum, updateStudy} from "../api/study";
import {analysisPlatforms, languages} from "../utils/constants";
import MSelect from "../components/MSelect";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import { Grid } from "@mui/material";

interface ReportItem {
    title: string;
    button?: JSX.Element;
    dateTitle: string;
    date?: Date | string;
    report?: string;
}

export const StudyInfoAdmin = (study: any) => {
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [idTrack, setIdTrack] = useState<string>(study?.id_track || '');
    const [studyLanguage, setStudyLanguage] = useState<string>(study?.study_language || '');
    const [jiraKey, setJiraKey] = useState<string>(study?.jira_key || '');
    const [idOrgExternal, setIdOrgExternal] = useState<string>(study?.id_organization_external || '');
    const [analysisPlatform, setAnalysisPlatform] = useState<string>(study?.analysis_platform || '');

    const [busy, setBusy] = useState<boolean>(false);

    if (!study) return <></>;

    const finalReport: ReportItem | null = study?.date_report_generated ? {
        title: 'Final Report',
        button: hasPermission!(PermissionsE["tab.review"]) ? (
            <MButton
                disabled={!study?.report}
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${baseUrls.adminFinalReportReviewBase}/${study.id}`)
                }}
            >
                Review Report
            </MButton>
        ) : <></>,
        dateTitle: 'Generated on:',
        date: study?.date_report_generated,
        report: study?.report
    } : null;

    const sourceReport: ReportItem | null = study?.date_source_report_received ? {
        title: 'Source Report',
        dateTitle: 'Received on:',
        date: study?.date_source_report_received,
        report: study?.source_report
    } : null

    const reportItems = [finalReport!, sourceReport!].filter(Boolean);

    const updateStudyHandler = async () => {
        setBusy(true);
        await updateStudy({
            id_track: idTrack,
            study_language: studyLanguage,
            jira_key: jiraKey,
            id_organization_external : idOrgExternal,
            analysis_platform : analysisPlatform
        },
            study?.id).finally(() => setTimeout(() => setBusy(false), 500));
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Typography
                        variant="h4"
                        fontStyle={{ "color": "#4F4949", "fontWeight": "bold" }}
                    >
                        Study details {' '}
                    </Typography>
                    <Box ml={1}>
                        <MStatusPlates value={study ? study?.status : undefined} />
                    </Box>
                    {(hasPermission!(PermissionsE["study.w_tech"]) || hasPermission!(PermissionsE["study.w_meta"])) && (
                        <Box ml={1}>
                            {isEdit ?
                                <>
                                    <>
                                        <MButton variant="text"
                                                 hidden={!isEdit}
                                                 color="error"
                                                 onClick={() => {
                                                     setIsEdit(false);
                                                 }}>
                                            Cancel
                                        </MButton>
                                        <MButton
                                            busy={busy}
                                            variant="text"
                                            hidden={!isEdit}
                                            onClick={() => {
                                                setIsEdit(false);
                                                updateStudyHandler();
                                            }}
                                        >
                                            Save
                                        </MButton>
                                    </>
                                </> : (
                                    <MButton variant="text"
                                             onClick={() => {
                                                 setIsEdit(true);
                                             }}
                                    >
                                        Edit
                                    </MButton>
                                )}
                        </Box>
                    )
                    }

                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MTextField
                            label="Enrollment"
                            value={study?.enrollment}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MTextField
                            label="Study creation date"
                            value={study?.date_start ? formatDateTimeToShow(study?.date_start) : 'N/A'}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MTextField
                            label="Analysis submission date"
                            value={study?.date_upload_processing ? formatDateTimeToShow(study?.date_upload_processing) : 'N/A'}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MTextField
                            label="Signing date"
                            value={study?.date_finished ? formatDateTimeToShow(study?.date_finished) : 'N/A'}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MTextField
                            label="Study type"
                            value={formatStudyType(study?.study_type)}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MSelect
                            label="Study language"
                            disabled={!isEdit}
                            value={studyLanguage}
                            options={languages}
                            onChange={(e) => setStudyLanguage(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MTextField
                            label="Study duration"
                            value={study?.duration_final?study?.duration_final.toString()+" days" : "N/A"}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            {
                hasPermission!(PermissionsE["study.r_tech"]) ? (
                    <Grid item xs={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MTextField
                                    label="Jira Key"
                                    disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                    value={jiraKey}
                                    onChange={(e) => setJiraKey(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MTextField
                                    label="ID Track"
                                    disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                    value={idTrack}
                                    onChange={(e) => setIdTrack(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MSelect
                                    label="Analysis platform"
                                    value={analysisPlatform}
                                    disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                    options={analysisPlatforms}
                                    onChange={(e) => setAnalysisPlatform(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MTextField
                                    label="ID Organization External"
                                    value={idOrgExternal}
                                    disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                    onChange={(e) => setIdOrgExternal(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MTextField
                                    label="Study duration(raw)"
                                    value={study?.duration_raw?study?.duration_raw.toFixed(1)+"h" : "N/A"}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MTextField
                                    label="Study duration(analyzed)"
                                    value={study?.duration_analyzed?study?.duration_analyzed.toFixed(1)+"h" : "N/A"}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : <></>
            }
            <Grid item xs={3}>
                {study.analysis_platform === "amps_cers" &&
                    hasPermission!(PermissionsE["study.r_tech"]) &&
                    study.status !== StudyStatusesEnum.inprogress &&
                    <>
                        <MButton sx={{"width":"100%"}}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     navigate(`${baseUrls.technicianStudyCheckpointBase}/${study.id}`)
                                 }}>
                            Process raw data
                        </MButton>
                        <br/>
                        <br/>
                    </>
                }

                {reportItems.map((r, rkey) => (
                    <Box key={rkey} mb={2}>
                        <Box mb={2} display="flex" alignItems="center">
                            <Typography variant="h5">{r.title}</Typography>
                            {r.button ? <Box ml={6}>{r.button}</Box> : <></>}
                        </Box>
                        <Box display="flex" alignItems="center" sx={{ fontWeight: '400' }}>
                            <Box mr={4}>
                                <Box>
                                    <Typography variant="body1">{r.dateTitle}</Typography>
                                </Box>
                                <Box>{r.date ? formatDateTimeToShow(r.date) : '—'}</Box>
                            </Box>
                            <Box>
                                {r.report ? (
                                    <Box
                                        display="flex"
                                        sx={{ textDecoration: 'underline', fontWeight: '600' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(r.report, "_blank");
                                        }}
                                    >
                                        View Report
                                    </Box>
                                ) : '—'}
                            </Box>
                        </Box>
                    </Box>
                ))}
                {study?.severity && <MSeverityPlates value={study ? study?.severity : undefined} />}
            </Grid>
        </Grid>
    )
}