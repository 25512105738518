import { networkLayer } from './networkLayer';
import { config } from "../config"
import {BaseDeviceInterface} from "./device";


export interface ClusterInterface  {
  name: string;
  region: string | undefined;
}
export const getClustersList = async (region_id:string | null) => {
  return await networkLayer('get', `${config.baseUrl}/clusters/list/`,null,{region_id:region_id});
}

export const addCluster = async (data:ClusterInterface) => {
  return await networkLayer('post', `${config.baseUrl}/clusters/create/`,data);
}

export const getCluster = async (id:string) => {
  return await networkLayer('get', `${config.baseUrl}/clusters/${id}/detail/`);
}