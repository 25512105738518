import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { MButton } from "./MButton";
import { formatDateToShow } from '../utils/utils';
import {useState} from "react";
import {requestCERReadLink} from "../api/checkpoints";
import MModal from "./MModal";
import {FeatureFlagsEnum, useFeatureFlags} from "../context/useFlags";

interface MReportItemProps {
  title?: string;
  reportDate: Date;
  url: string;
  isLastChild?: boolean;

  values?: any;
}

export const MReportItem = (props: MReportItemProps) => {
    const { hasFeatureFlag } = useFeatureFlags();

    const { title = 'Final Report', reportDate, url, isLastChild = false, values = {}} = props;
    console.log(values);

    const [busy, setBusy] = useState(false);

    const [errorReviewopen, setErrorReviewopen] = useState(false);
    const handleErroReviewModalOpen = () => setErrorReviewopen(true);
    const handleErroReviewModalClose = () => {setErrorReviewopen(false); return false;};

    const [errorReview, setErrorReview] = useState<string>("");
    const [errorReviewCode, setErrorReviewCode] = useState<string>("");

    const processReviewLink = async() => {
        setBusy(true);
        let data = await requestCERReadLink(values.id, null);
        let link = data.data.link;

        setErrorReview(data.data.error_message);
        setErrorReviewCode(data.data.error_code);

        if (link === null){
            setErrorReviewopen(true);
        } else {
            window.open(link, "_blank")
        }


        setBusy(false);
    }

    const renderReviewError = () => {
        return (
            <Box
                p={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
            >
                {/* Title */}
                <Typography variant="h4" sx={{ textAlign: "center", mb: 4 }}>
                    Review Error
                </Typography>

                {/* Error Details */}
                <Typography variant="h6" sx={{ mb: 2, textAlign: "left", width: "100%" }}>
                    Error Code:
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 4,
                        textAlign: "left",
                        width: "100%",
                        color: "text.secondary",
                    }}
                >
                    {errorReviewCode}
                </Typography>

                <Typography variant="h6" sx={{ mb: 2, textAlign: "left", width: "100%" }}>
                    Error Message:
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 10,
                        textAlign: "left",
                        width: "100%",
                        color: "text.secondary",
                    }}
                >
                    {errorReview}
                </Typography>

                {/* Close Button */}
                <MButton
                    disabled={busy}
                    onClick={() => handleErroReviewModalClose()}
                    sx={{ ml: 2 }}
                >
                    Close
                </MButton>
            </Box>
        )
    }

  return (
    <>
        <MModal
            open={errorReviewopen}
            handleClose={handleErroReviewModalClose}
            width={480}
            height="auto"
        >
            {renderReviewError()}
        </MModal>
      <Divider />
      <Box display="flex" justifyContent="space-between" py={4}>
        <Box display="flex" alignItems="center">
          <Typography sx={{ width: 160, fontSize: 18, fontWeight: 600, pl: 2 }}>{title}</Typography>
          <Typography>Generated on {formatDateToShow(reportDate)}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            '& > *': {
              marginLeft: 4 
            }
          }}
        >
            {values?.analysis_platform === "amps_cers" && hasFeatureFlag!(FeatureFlagsEnum.RAW_DATA_ACCESS) && (
                <Box>
                    <MButton busy={busy} onClick={() => processReviewLink()}>Raw data</MButton>
                </Box>)
            }
          <Box>
            <MButton onClick={() => window.open(url, "_blank")}>View Report</MButton>
          </Box>
        </Box>
      </Box>
      {isLastChild && <Divider />}
    </>
  );
}

export default MReportItem;
