import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MStatusPlates } from "../components/MStatusPlates";
import { MReportItem } from "../components/MReportItem";
import {
  formatDateToShow,
  formatDateTimeToShow,
  formatStudyType,
  formatEnrollment,
  isMCT,
  isTwoWeekHolter
} from '../utils/utils';
import {Fragment} from "react";
import UploadDMS from "./UploadDMS";
import {StudyStatusesEnum, updateStudy} from "../api/study";
import {MButton} from "../components/MButton";
import {useState} from "react";
import {DMSEnrollmentModal} from "../components/MModals/DMSENrollmentModal";
import MModal from "../components/MModal";
import { useNavigate } from 'react-router-dom';
import {FeatureFlagsEnum, useFeatureFlags} from "../context/useFlags";
import {usePermissions} from "../context/usePermissions";

interface StudySummaryProps {
  values?: any;
  devices?: any[];
  devicesMCT?: any[];
  reports?: any[];
}

export const StudySummary = ({ values, devices = [], devicesMCT = [], reports = [] }: StudySummaryProps) => {
  const navigate = useNavigate();
  const { hasFeatureFlag } = useFeatureFlags();

  const [isSetupDeviceOpen, setIsSetupDeviceOpen] = useState<boolean>(false);
  const [isSetupDeviceManuallyOpen, setIsSetupDeviceManuallyOpen] = useState<boolean>(false);
  const [isManualEnrollButtonBusy, setIsManualEnrollButtonBusy] = useState<boolean>(false);
  const renderDeviceAndDate = () => {
    const currentStudyType = (isMCT(values.study_type) || isTwoWeekHolter(values.study_type))

    if (currentStudyType) {
      return {
        devices: devicesMCT.length ? devicesMCT.map((d: any, key: number) => (
          <span key={key} style={{ display: 'block' }}>
            {d.device_serial_number}
          </span>
        )) : '—',
        startDate: values.date_start ? formatDateTimeToShow(values.date_start) : '—'
      }
    } else if (!currentStudyType) {
      return {
        devices: devices.length ? devices.map((d: any, key: number) => (
          <span key={key} style={{ display: 'block' }}>
            {d.device_serial_number}
          </span>
        )) : '—',
        startDate: values.date_start ? formatDateTimeToShow(values.date_start) : '—'
      }
    }

    return {
      devices: '—',
      startDate: '—'
    }
  }

  const patientInfo = [
    {
      label: 'Status',
      value: <MStatusPlates disableStyling value={values.status} />
    },
    {
      label: 'Date Created',
      value: formatDateToShow(values.date_start)
    },
    {
      label: 'Recording Type',
      value: formatStudyType(values.study_type)
    },
    {
      label: 'Enrollment',
      value: formatEnrollment(values.enrollment)
    },
    {
      label: 'Device ID',
      value: renderDeviceAndDate().devices
    },
    {
      label: 'Test start time',
      value: renderDeviceAndDate().startDate
    }
  ]

  const onChangeSetupDeviceVisilibitily = (isOpen: boolean) => {
    setIsSetupDeviceOpen(isOpen);
  }

  const uploadSection = (device: any) => {
    if (device.date_upload !== "None") {
      return <Typography sx={{ fontSize: 16, py: 3 }}>The files have been uploaded.</Typography>
    }

    return <UploadDMS device={device} patientIdUnique={values.patient.id_unique} />
  }

  const handleEnrollStudy = async() => {
    await setIsManualEnrollButtonBusy(true);
    let payload = {
      "status" : StudyStatusesEnum.inprogress
    }

    let data = await updateStudy(payload,values.id);
    navigate("/");
  }

  return (
    <>
      { values && devices.length > 0 && (
          <>
            <DMSEnrollmentModal isOpenedProp={isSetupDeviceOpen}
                                onChangeModalVisibility={onChangeSetupDeviceVisilibitily}
                                idStudy={values.id}
                                isNewStudy={false}
                                firstName={values.patient.first_name}
                                lastName={values.patient.last_name}
                                birthday={values.patient.birthday}
                                startTime={devices[0].date_start}
                                deviceSNStudy={devices[0].device_serial_number}
                                idPatient={values.patient.id_unique}
                                gender={values.patient.gender === "male" ? "M" : "F"}/>

                <MModal
                    open={isSetupDeviceManuallyOpen}
                    handleClose={() => {
                      //setIsPopUpOpen(false)
                    }}
                    width={560}
                    height="auto"
                >
                  <Box p={3} sx={{textAlign: "center"}}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                      Setup via PC App
                    </Typography>
                    <Typography variant="body2" sx={{mb: 2}}>
                      If you have troubles enrolling via web, you can alternatively use PC based app to it.
                    </Typography>
                    <Typography variant="body2" sx={{mb: 1}}>
                      1. Open Enroll app (only)
                    </Typography>
                    <Typography variant="body2" sx={{mb: 1}}>
                      2. Setup device there
                    </Typography>
                    <Typography variant="body2" sx={{mb: 5}}>
                      3. While device is plugged in, <b>Confirm</b> it here
                    </Typography>
                    <Typography variant="body2" sx={{mb: 3}}>
                      Contact Mawi representative for app setup instructions.
                    </Typography>


                    <Box sx={{textAlign: "center", display: "flex", justifyContent: "center", mt: 5, gap: 4}}>
                      <MButton variant="outlined"
                               busy={isManualEnrollButtonBusy}
                               onClick={() => setIsSetupDeviceManuallyOpen(false)}>
                        Cancel
                      </MButton>
                      <MButton
                          busy={isManualEnrollButtonBusy}
                          onClick={() => handleEnrollStudy()}>
                        Confirm it's done
                      </MButton>
                    </Box>
                  </Box>
                </MModal>
          </>
      )
      }
      <Box display="flex" justifyContent="space-between" mb={7.5}>
        {
          patientInfo.map((pi, key) =>(
            <Box key={key}>
              <Typography sx={{ color: 'rgba(79, 73, 73, 0.5)', fontSize: 14, pb: 0.5 }}>{pi.label}</Typography>
              <Typography>{pi.value}</Typography>
            </Box>
          ))
        }
      </Box>
      <Box sx={{width:"100%"}}>
        {values.status === StudyStatusesEnum.not_enrolled && hasFeatureFlag!(FeatureFlagsEnum.DMS_WEB_ENROLLMENT) && (
            <>
              <Box mb={2}>

                <Typography variant="h5">Enrollment</Typography>
                <Box mt={2} mb={2} gap={2} display="flex" >
                  <MButton onClick={() => setIsSetupDeviceOpen(true)}>Setup the device</MButton>
                  <MButton variant="text" onClick={() => setIsSetupDeviceManuallyOpen(true)}>
                    Enroll via desktop app
                  </MButton>
                </Box>
              </Box>

            </>
        )

        }
        {values.status !== StudyStatusesEnum.not_enrolled && devices.length > 0 && devices[0].device_serial_number.length > 5 && (
          <>
            <Box mb={2}>
              <Typography variant="h5">Uploads</Typography>
            </Box>
            {devices && devices?.map((device, index) => (
                <Fragment key={index}>
                  {uploadSection(device)}
                </Fragment>
            ))}
          </>
        )}
      </Box>
      {
        (!!reports.length || values.report) && (
          <>
            <Typography variant="h5">Reports</Typography>
            <Box pt={3}>
              <>
                {reports && reports.map((report, key) => (
                  <MReportItem 
                    key={key}
                    title="Report"
                    reportDate={report.date_start}
                    url={report.url}
                    isLastChild={reports.length - 1 === key}
                    values={values}
                  />
                ))}
                {values.report && (
                  <MReportItem 
                    reportDate={values.date_start}
                    url={values.report}
                    isLastChild
                    values={values}
                  />
                )}
              </>
            </Box>
          </>
        )
      }
    </> 
  );
};

export default StudySummary;