import { createContext, useContext, useMemo,useEffect, ReactElement, useCallback } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useAuth, User } from "./useAuth";

interface FeatureFlagsContextInterface {
    hasFeatureFlag?: (feature: string) => boolean;
}

const defaultFeatureFlagsContext = {};

const FeatureFlagsContext = createContext<FeatureFlagsContextInterface>(defaultFeatureFlagsContext);

interface FeatureFlagsProviderInterface {
    children: ReactElement;
}

export const checkFeatureFlags = (user: User | null): boolean => {
    if (!user || !user.staff_profile.feature_flags) {
        return false;
    }

    return "feature_flags" in user.staff_profile;
};


// Define feature flags as an enum or object for better type safety and clarity
export const FeatureFlagsEnum = {
    RAW_DATA_ACCESS: "raw_data_view",
    DMS_WEB_ENROLLMENT: "dms_web_enrollment"
};


export const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderInterface) => {
    const { user, logout } = useAuth();

    // useEffect(() => {
    //     if (!checkFeatureFlags(user)) {
    //         //logout();
    //     }
    // }, [user]);

    const hasFeatureFlag = useCallback((feature: string): boolean => {
        console.log("featureFlags", feature);
        console.log(user?.staff_profile)

        if (!checkFeatureFlags(user)) {
            return false;
        }

        return user?.staff_profile?.feature_flags.includes(feature) || false;
    }, [user]);

    const value = useMemo(
        () => ({
            hasFeatureFlag
        }),
        [user]
    );

    return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => {
    return useContext(FeatureFlagsContext);
};