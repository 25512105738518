import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export interface BreadcrumbItem {
    pageName: string,
    to: string,
    region? : string | null,
    cluster? : string | null
}

interface MBreadcrumbsProps {
    homepage: BreadcrumbItem;
    breadcrumbs: BreadcrumbItem[];
}

export const MBreadcrumbs = (props: MBreadcrumbsProps) => {
    const { homepage, breadcrumbs } = props;

    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={homepage.to}>
          {homepage.pageName}
        </Link>
        {breadcrumbs.map((value, index) => {
          const last = index === breadcrumbs.length - 1;
  
          return last ? (
            <Typography color="text.primary" key={value.to}>
              {value.pageName}
            </Typography>
          ) : (
            <Link to={value.to} key={value.to}>
              {value.pageName}
            </Link>
          );
        })}
      </Breadcrumbs>
    );
}
