import { networkLayer } from './networkLayer';
import { config } from "../config"
import {BaseDeviceInterface} from "./device";


export interface ClinicInterface extends BaseDeviceInterface {
    region : string | null;

    cluster : string | null;

    name: string;

    allowed_studies?: any;

    study_language: string;

    id_organization_external?: string;

    analysis_platform?: string;
}

// CLinics API
export const getClinicsList = async (cluster_id:string | null) => {
    return await networkLayer('get', `${config.baseUrl}/clinics/list/`,null,{"cluster_id":cluster_id});
}

export const addClinic = async (data:ClinicInterface) => {
    return await networkLayer('post',`${config.baseUrl}/clinics/create/`,data)
}

export const getClinicDetail = async (id:string | undefined) => {
    return await networkLayer('get', `${config.baseUrl}/clinics/${id}/detail/`);
}

export const updateClinic = async (data:any, id:any) => {
    return await networkLayer('patch',`${config.baseUrl}/clinics/${id}/update/`,data)
}